import React, { useMemo } from "react";
import { VideoDetectionResult } from "@core/types/domain/video/VideoDetectionType";
import { useDetectionFilter } from "~/screens/app/detection/stats/components/filter/DetectionFilter";
import { useDetectionSummary } from "~/screens/app/detection/data/useDetectionSummary";
import { useAllSummedSizeStats } from "~/screens/app/detection/data/sumBrandStats";
import { mapN } from "~/utils/jsUtils";
import { FirstSocialLogosPage } from "~/screens/pdf/dashboard/social-logos/pages/FirstSocialLogosPage";
import { SocialLogosPage } from "~/screens/pdf/dashboard/social-logos/pages/SocialLogosPage";
import PdfPage from "~/layouts/pdf/PdfPage";
import PdfProjectHeader from "~/screens/pdf/PdfProjectHeader";
import PdfFooter from "~/layouts/pdf/layout/PdfFooter";
import { useSortedBrands } from "~/screens/pdf/dashboard/logo/useSortedBrands";

const brandsPerPage = 15;

export function PdfSocialLogoPages({
  results,
}: {
  results: VideoDetectionResult[];
}) {
  const { filter } = useDetectionFilter();
  const summary = useDetectionSummary(results, filter);
  const summedByBrand = useAllSummedSizeStats(summary.summed);
  const { sortedBrands } = useSortedBrands(summedByBrand);

  const pages = useMemo(() => {
    if (!summedByBrand || !sortedBrands) return undefined;
    const pages = [];
    const pagesCount = Math.ceil(sortedBrands.length / brandsPerPage);
    const maxIndex = sortedBrands.length;
    pages.push(
      ...mapN(pagesCount, (page) => {
        const from = page * brandsPerPage;
        const to = from + brandsPerPage - 1;
        const brandsToShow = sortedBrands.filter(
          (_, i) => i >= from && i <= to
        );
        const countInfo =
          page === 0
            ? `Top ${brandsPerPage}`
            : `${from + 1}-${Math.min(to, maxIndex)}`;
        const pageProps = {
          brandsTitle: `Brand statistics (${countInfo})`,
          summed: summary.summed,
          summedByBrand: summedByBrand,
          brandsWhitelist: brandsToShow,
        };
        if (page === 0) {
          // render first page
          return (
            <FirstSocialLogosPage
              {...pageProps}
              totalViews={summary.totalViews}
              totalLength={summary.totalLength}
              results={results}
            />
          );
        }
        return <SocialLogosPage {...pageProps} />;
      })
    );
    return pages;
  }, [summedByBrand, sortedBrands, summary]);
  return (
    <>
      {pages?.map((page, i) => (
        <Page key={i} number={i + 1} maxPages={pages.length}>
          {page}
        </Page>
      ))}
    </>
  );
}

function Page(props: { children: any; number: number; maxPages: number }) {
  return (
    <PdfPage
      header={<PdfProjectHeader />}
      footer={<PdfFooter number={props.number} maxPages={props.maxPages} />}
    >
      {props.children}
    </PdfPage>
  );
}
