import React, { useMemo } from "react";
import { ProjectSelectionContext } from "~/elements/context/project/ProjectSelectionContext";
import PdfScreen from "~/layouts/pdf/PdfScreen";
import { PdfSocialLogoPages } from "~/screens/pdf/dashboard/social-logos/PdfSocialLogoPages";
import { useVideoDetectionResults } from "~/screens/app/detection/data/fetchDetectionResults";
import { useSelectedProject } from "~/store/project/projectHooks";

function PdfSocialLogoPagesContainer() {
  const project = useSelectedProject();
  const projectIds = useMemo(() => project && [project?.id], [project]);
  const { data: results } = useVideoDetectionResults(projectIds);
  if (!results) return null;
  return <PdfSocialLogoPages results={results} />;
}

export function PdfSocialLogosScreen() {
  return (
    <ProjectSelectionContext resolveFromUrl>
      <PdfScreen>
        <PdfSocialLogoPagesContainer />
      </PdfScreen>
    </ProjectSelectionContext>
  );
}
