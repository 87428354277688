import { BrandStats } from "@core/types/detection/DetectionTypes";
import { useMemo } from "react";

export function useSortedBrands(summedByBrand: Record<string, BrandStats>) {
  const sortedNames = useMemo(() => {
    if (!summedByBrand) return undefined;
    const entries = Object.entries(summedByBrand);
    entries.sort((a, b) => {
      return a[1].score < b[1].score ? 1 : -1;
    });
    return entries.map((entry) => entry[0]);
  }, [summedByBrand]);
  return useMemo(() => {
    return {
      sortedBrands: sortedNames,
    };
  }, [sortedNames]);
}
