import React from "react";
import { faChartBar, faEye, faVideo } from "@fortawesome/free-solid-svg-icons";
import styled from "@emotion/styled";
import { VideoDetectionResult } from "@core/types/domain/video/VideoDetectionType";
import ReactCountUp from "react-countup";
import Widget from "~/components/widget/Widget";
import {
  LogoBrandsTable2,
  LogoBrandsTable2Props,
} from "~/screens/pdf/dashboard/logo/LogoBrandsTable2";
import NumberWidget from "~/screens/app/dashboard/widgets/NumberWidget";
import { formatDurationFull } from "~/utils/formatUtils";
import WidgetGrid from "~/components/widget/WidgetGrid";
import { DetectionSummary } from "~/screens/app/detection/data/useDetectionSummary";

interface PdfSocialLogosPageProps
  extends LogoBrandsTable2Props,
    Pick<DetectionSummary, "totalViews" | "totalLength"> {
  results: VideoDetectionResult[];
  brandsTitle: string;
}

export function FirstSocialLogosPage({
  results,
  totalLength,
  totalViews,
  brandsTitle,
  ...tableProps
}: PdfSocialLogosPageProps) {
  return (
    <>
      <PageTitle>Social Logo Report</PageTitle>
      <Grid>
        <NumberWidget
          title={"Total videos"}
          subTitle={"total number of videos containing logos"}
          icon={faVideo}
          value={results.length}
          subValue={<span>{formatDurationFull(totalLength)}</span>}
        />
        <NumberWidget
          title={"Total views"}
          subTitle={"total views of footage"}
          subValue={<ReactCountUp end={10000} suffix={" €"} separator={"."} />}
          icon={faEye}
          value={totalViews}
        />
      </Grid>
      <Widget title={brandsTitle} icon={faChartBar}>
        <LogoBrandsTable2 {...tableProps} />
      </Widget>
    </>
  );
}

const Grid = styled(WidgetGrid)`
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
`;

const PageTitle = styled.h1`
  font-size: 1.4em;
  margin-bottom: 12px;
  font-weight: bold;
`;
