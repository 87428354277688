import React from "react";
import { faChartBar } from "@fortawesome/free-solid-svg-icons";
import Widget from "~/components/widget/Widget";
import {
  LogoBrandsTable2,
  LogoBrandsTable2Props,
} from "~/screens/pdf/dashboard/logo/LogoBrandsTable2";

interface PdfSocialLogosPageProps extends LogoBrandsTable2Props {
  brandsTitle: string;
}

export function SocialLogosPage({
  brandsTitle,
  ...tableProps
}: PdfSocialLogosPageProps) {
  return (
    <>
      <Widget title={brandsTitle} icon={faChartBar}>
        <LogoBrandsTable2 {...tableProps} />
      </Widget>
    </>
  );
}
